"use client"
import {Card, CardBody} from "@nextui-org/card";
import {Input} from "@nextui-org/input";
import {Tools} from '@prisma/client';
import {Link} from '@repo/i18n/navigation';
import {useTranslations} from 'next-intl';
import {FC, useCallback, useState} from 'react';
import {FaSearch} from 'react-icons/fa';
import {Icons} from "./primitives/Icons";
import {EmptyIcon} from "./svg-icons.tsx";

type FeaturesSectionProps = {
    initialTools: Tools[];
};

export const FeaturesSection: FC<FeaturesSectionProps> = ({initialTools = []}) => {
    const t = useTranslations('FeaturesSection');
    const [tools, setTools] = useState<Tools[]>(initialTools);
    const handleSearch = useCallback((e: string) => {
        if (e.length <= 0) {
            setTools(() => initialTools)
            return
        }
        setTools(() => {
            return initialTools.filter(it => it.name.toLowerCase().includes(e.toLowerCase()))
        });
    }, [setTools])

    return (
        <section className="py-16  bg-gradient-to-b from-[#FEEDFE] to-white rounded-xl">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-purple-600 dark:text-purple-400">{t('title')}</h2>
                <div className="mb-12 flex justify-center">
                    <div className="flex w-full max-w-xl">
                        <Input
                            isClearable
                            className="flex-grow "
                            classNames={{
                                input: "bg-transparent",
                                innerWrapper: "bg-transparent",
                                inputWrapper: "bg-white"
                            }}
                            placeholder={t('searchPlaceholder')}
                            onInput={(e) => handleSearch((e.target as any).value as string)}
                            onClear={() => handleSearch("")}
                            size="lg"
                            startContent={<FaSearch className="text-purple-400 dark:text-purple-300"/>}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {Array.isArray(tools) ? tools.map((tool) => (
                        <Card key={tool.id} isPressable className="hover:shadow-lg transition-shadow">
                            <CardBody>
                                <Link href={`/tools/${tool.slug}`} locale={tool.locale}
                                      className="inline-flex items-center gap-2">
                                    <Icons name={tool.name}/>
                                    <h3 className="font-semibold text-purple-600 dark:text-purple-400 text-center">{tool.name}</h3>
                                </Link>
                            </CardBody>
                        </Card>
                    )) : <EmptyIcon/>}
                </div>
            </div>
        </section>
    );
};
